export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '0px',
    fontSize: ['0.75rem', '0.85rem', '1rem'],
    fontWeight: 'bold',
    fontFamily: 'body',
    transition: 'all ease-in-out 0.5s',
    padding: ['0.7rem 1rem', '', '1rem 2rem'],
    ':hover': {
      backgroundColor: 'black',
      borderColor: 'black'
    }
  },
  secondary: {
    backgroundColor: 'secondary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'secondary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '0px',
    fontSize: ['0.9rem', '', '1rem'],
    fontFamily: 'body',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
