export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'black',
      padding: ['0.5rem', '0.5rem', '0.5rem', '1rem'],
      borderBottom: '1px solid',
      borderColor: 'primary'
    },
    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '1x solid',
      borderColor: 'primary',
      '.logoLocationContainer': {
        // display: 'none',
        margin: ['', '', '', '0 auto 0 0'],
        position: ['', '', '', 'static'],
        '.container': {
          // padding: ['0.5rem'],
        },

        '.locationSwitcher': {
          justifyContent: 'center',
          button: {
            fontSize: '0.8rem'
          }
        },
        '.popUp': {
          transform: 'unset'
        }
      }
    },
    '.logoLocationContainer': {
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      padding: '0rem',

      '.logo': {
        img: {
          filter: 'unset',
          maxHeight: ['80px', '80px', '80px', '80px']
        }
      },
      '.logoScrolled ': {
        img: {
          //   filter: 'brightness(0) invert(1)',

          maxHeight: ['60px', '60px', '60px', '60px'],
          height: 'unset'
        }
      }
    },

    '.smallNavMenu': {
      '.navItem': {
        color: 'white',
        margin: ['', '', '', '0.5rem', '0.5rem'],
        textTransform: 'none',
        a: {
          fontSize: ['1rem', '', '', '', '1.5rem'],
          textTransform: 'capitalize',
          color: 'white',
          fontWeight: 'bold',
          ':hover': {
            textDecoration: 'underline',
            color: 'primary'
          }
        }
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          color: 'primary',

          padding: '0.5rem',
          border: 'solid 1px',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      border: 'none',
      height: '45px',
      margin: ['auto 0rem auto auto', '', '', '0rem 0rem 0rem 0.5rem'],
      div: {
        backgroundColor: 'white'
      },
      'div:nth-of-type(2)': {
        width: '70%'
      }
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      width: ['85%', '60%', '50%', '40%'],
      color: 'white',
      animation: 'none',
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '100%'],
        maxWidth: '250px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.navItem': {
        a: {
          fontSize: ['1.25rem', '1.25rem', '1.5rem', '2rem'],
          textTransform: 'capitalize'
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right']
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          color: 'white',
          svg: {
            path: { fill: 'white' }
          }
        },
        '.socialContainer': {
          flexDirection: ['', '', '', 'row']
        }
      },

      '.navItemDropdownMenu': {
        backgroundColor: 'black',
        position: ['static', 'static', 'static', 'static']
      },
      '.phoneContainer': {
        display: 'none'
      }
    },

    '.navItemDropdownMenu': {
      backgroundColor: 'black',
      width: 'fit-content'
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      left: '0rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    maxWidth: 'unset',
    padding: ['3rem 1rem 7rem', '', '2rem 5rem 2rem'],
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },
    borderTop: 'solid 5px',
    borderColor: 'primary',
    // '.multiButtonContainer': {
    //   a: {
    //     variant: 'buttons.primary',
    //   },
    // },
    '.sectionHeading': {
      fontSize: ['1.5rem', '1.5rem', '1.5rem', '2.5rem']
    },
    '.gonationLogo': {
      width: 'fit-content'
    },
    backgroundColor: 'black',
    '.contactDetails-container': {
      display: 'none'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '3.5rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: 'white',
    order: '2'
  },
  subtitle: {
    color: 'primary',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',

    order: '1',
    border: 'none',
    fontWeight: 'bold',
    fontFamily: 'body',
    textTransform: 'capitalize'
  },
  text: {
    textAlign: 'left',
    lineHeight: '1.75',
    fontSize: '0.95rem',
    order: '3',
    p: {
      lineHeight: '1.75',
      letterSpacing: '0.5px',
      textAlign: 'inherit',
      fontSize: 'inherit'
    },
    'p:last-child': {
      margin: '0rem'
    }
  },

  // borders: {
  //   '::before': {
  //     content: "''",
  //     backgroundImage:
  //       'url(https://res.cloudinary.com/gonation/image/upload/v1638454696/sites/canoe/canoe-logo.png)',
  //     backgroundSize: '50px',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundPosition: 'center center',
  //     margin: ['0rem 0rem 1.5rem', '', '0rem 0rem 3rem'],
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     paddingBottom: '1rem',
  //     height: '50px',
  //     width: '100%',
  //     borderBottom: '2px dotted',
  //     borderColor: 'primary',
  //   },

  //   '::after': {
  //     content: "''",
  //     order: '6',
  //     backgroundImage:
  //       'url(https://res.cloudinary.com/gonation/image/upload/v1638454696/sites/canoe/canoe-logo.png)',
  //     backgroundSize: '50px',
  //     backgroundRepeat: 'no-repeat',
  //     backgroundPosition: 'center center',
  //     margin: '3rem 0rem 0rem',
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     paddingBottom: '1rem',
  //     height: '50px',
  //     width: '100%',
  //     borderTop: '2px dotted',
  //     borderColor: 'primary',
  //   },
  // },

  forkBeforeAndAfter: {
    '::before': {
      content: "'ψ'",
      marginBottom: '2rem',
      fontSize: '3rem'
    },
    '::after': {
      content: "'ψ'",
      marginTop: '1rem',
      fontSize: '3rem',
      transform: 'rotate(180deg)'
    }
  },

  sideBySide1: {
    flexWrap: 'wrap',
    padding: ['1rem', '1rem', '3rem', '4rem'],
    '.lazyload-wrapper': {
      border: 'solid 5px',
      borderColor: 'primary',
      img: {
        maxHeight: ['', '', '', '80vh']
      }
    },
    '.content': {
      padding: ['2rem 0.5rem 2rem 2.5rem', ' 2.5rem', '2.5rem', '3rem', '4rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  centerBlock: {
    '.section': {
      variant: 'customVariants.borders',
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      backgroundColor: 'rgb(0 0 0 / 80%)',
      margin: 'auto 4rem auto auto',
      maxWidth: ['', '', '50%', '33.3%'],
      alignItems: 'flex-start',
      borderLeft: '5px solid #928776'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },

    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    padding: '3rem',
    margin: '0rem auto -4.5rem',
    width: ['calc(100%)', '', 'calc(100%)'],
    '.section': {
      margin: 'auto auto',
      // display: 'none',
      padding: '0.5rem'
    },
    '.title': {
      variant: 'customVariants.title',
      fontSize: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      color: 'white',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      textAlign: 'center',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '::before': {
      content: "'Scroll'",
      width: '0.25rem',
      fontSize: '0.9rem',
      textShadow: '1px 1px 10px black',
      borderLeft: 'solid 2px',
      borderColor: 'primary',
      padding: '0rem 1rem 3rem',
      wordWrap: 'break-word',
      whiteSpace: 'break-spaces',
      display: 'inline',
      bottom: ['3rem', '5rem'],
      left: ['20%', '10%'],
      position: 'absolute',
      letterSpacing: '1px'
    },

    '::after': {
      content: "''"
      //   width: '75px',
      //   height: '75px',
      //   backgroundColor: 'primary',
      //   position: 'absolute',
      //   bottom: '2.5rem',
      //   left: '50%',
      //   transform: 'translateX(-50%) rotate(\n45deg)',
      //   border: 'solid 11px',
      //   borderColor: 'background'
    }
  },

  homepageHeroShout: {},

  locationsTitle: {
    variant: 'customVariants.forkBeforeAndAfter',
    background: 'none',
    padding: '0rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ['2rem'],
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center'
    },
    '.text': {
      variant: 'customVariants.text',
      lineHeight: '1',
      textAlign: 'center',
      'p:last-of-type': {
        margin: '0rem'
      }
    }
  },

  locationBoxes: {
    flexWrap: 'wrap',
    flexDirection: ['column', 'column', 'row'],
    '.box': {
      backgroundColor: 'black',
      margin: '2rem',
      padding: '2rem',
      border: 'solid 3px',
      borderColor: 'primary',
      flex: '1',
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '2rem', '2rem', '2rem', '2rem'],
        order: 'unset',
        borderBottom: '1px solid',
        width: 'fit-content',
        textAlign: 'center',
        margin: 'auto auto 1.5rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    borderColor: 'primary',
    borderBottom: '5px solid'
  },

  homepageMenu: {
    variant: 'customVariants.centerBlock'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
    borderTop: '5px solid',
    borderColor: 'secondary'
  },

  homepagePrivateDining: {
    variant: 'customVariants.centerBlock',
    '.section': {
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      backgroundColor: 'rgb(0 0 0 / 63%)',
      margin: ['auto auto auto 3rem', 'auto auto auto 4rem'],
      maxWidth: ['', '', '50%', '33.3%'],
      alignItems: 'flex-start',

      borderRight: ['', '', '5px solid #928776'],
      borderLeft: ['5px solid #928776', '', 'none']
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    borderBottom: '5px solid',
    borderColor: 'secondary'
  },

  homepageContact: {
    variant: 'customVariants.centerBlock',
    '.section': {
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      backgroundColor: 'rgb(0 0 0 / 63%)',
      margin: '0rem auto 0rem 0rem',
      maxWidth: ['', '', '50%', '33.3%'],
      alignItems: 'flex-start',

      borderRight: ['', '', '5px solid #928776'],
      borderLeft: ['5px solid #928776', '', 'none']
    }
  },

  homepageMailList: {
    variant: 'customVariants.sideBySide1'
  },

  homepageInstagram: {
    '.logoAndTextContainer ': {
      // margin: '3rem 0rem 2rem',
      display: 'none'
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%']
    }
  },

  homepageShout: {
    borderTop: 'solid 3px',
    borderColor: 'secondary',
    position: 'relative',
    marginTop: '-4rem',
    flexDirection: ['column', 'column', 'row'],

    '.imageContainer': {
      order: ['3', '', 'unset'],
      width: ['100%', '100%', '50%'],
      '.image': {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    },
    '.content': {
      width: ['100%', '100%', '50%']
    },
    '.title': {
      variant: 'customVariants.title',
      marginBottom: '0rem',
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem', '2rem'],
      color: 'secondary',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      width: 'fit-content',
      textTransform: 'capitalize'
    },

    '.text': {
      variant: 'customVariants.text',
      marginBottom: '1.5rem',
      textAlign: 'center',
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '1.75rem', '1.75rem'],
      color: 'white'
    },
    '.date': {
      order: '2',
      marginBottom: '1rem',
      fontSize: ['1rem', '1rem', '1rem'],
      color: '#ae222d',
      textAlign: 'center'
    },

    '.shoutCTABtns ': {
      order: '5',
      justifyContent: 'center',

      width: '100%',
      '.shoutCTA': {
        variant: 'buttons.primary'
      }
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    '.menuTab': { color: 'white' }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    },
    '.albumName': {
      textTransform: 'capitalize'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'dark',
    backgroundImage: 'none',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  hours: {},

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'primary',
      color: 'white',
      padding: '1rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.4rem', '1.5rem', '1.75rem', '1.75rem', '2rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ============================
  // ? ====  Reservations page  ===
  // ? ============================

  reservationsSection: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 1rem',
    flexDirection: 'column',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      fontSize: ['2rem', '', '2.5rem'],
      fontWeight: '400'
    }
  },

  // ? ============================
  // ? ===  Private Dining page  ==
  // ? ============================

  privateDiningHero: {
    '.hero_content_container': {
      backgroundColor: 'rgb(0 0 0 / 69%)',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        color: '#8a242c'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  privateDiningHeroShout: {
    display: 'none'
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
